.home__modal-btn {
  cursor: pointer;
}
.home__modal-btn:hover {
  text-decoration: underline;
}

.home__mission-title-container {
  padding: 20px 10px 10px 10px;
}

.home-title{
  font-weight: 600;
}

.home__mission-title,
.home__mission-text {
  color: hsl(176, 100%, 84%);
}

.home__mission-text {
  font-size: 1.2rem;
  color: hsl(176, 100%, 84%);
  font-weight: 600;
  padding: 0 0 30px 0;
}


.home__welcome-text{
  color: hsl(0, 0%, 29%);
  padding: 20px;
  font-weight: 600;
}

.home__header-container {
  padding: 20px;
  /* background: hsl(220, 70%, 30%); */
  background: linear-gradient(
    45deg,
    hsl(220, 70%, 30%), hsl(220, 85%, 37%)
    );
  border-top: 12px solid hsl(220, 70%, 20%);
}

.home__welcome-header-text {
  color: hsla(0, 0%, 100%, .90);
  text-shadow: 3px 1px 2px hsl(220, 70%, 20%);
  font-size: 3rem;

}


.home__welcome-text {
  margin: -10px 0 -10px 0;
  color: hsla(0, 0%, 100%, .90);
  line-height: 2.5em;
  font-size: 1.1rem;

}


/* JOIN US CONTAINER */
.home__join-us-container {
  padding: 20px;
  border-top: 6px solid hsl(357, 80%, 30%);
  background: hsl(357, 92%, 41%);
}

.home__join-us-header {
  color: hsl(0, 0%, 100%, 0.86);
  font-size: 1.5rem;
  font-weight: 700;
}

button.home__join-us-button  {
  background: hsl(357, 80%, 30%);
  border: none;
}

button.home__join-us-button:hover {
  background: hsl(357, 80%, 25%);
  border: none;
}

.home-img{
  position: absolute;
  max-width: 300px;
  right:100px;
  top: 500px;
  z-index: 10;
}