@import url(https://fonts.googleapis.com/css?family=Lato);
.appbar__container {
    border-top: 10px solid hsl(357, 80%, 30%);
    /* border-bottom: 2px solid #B60101; */
    box-shadow: 0px 0px 2px 0px #3e3e3e;
}

.nav-adjustments {
    padding-right: 20px;
    margin-bottom: 0px;
    list-style: none;
    bottom: 20px;
    position: absolute;
    right: 0;
}

.appbar__nav-link {
    color:#B60101;
    font-size: 1.2rem;
    font-weight: 600;
}

.appbar__nav-link:hover {
    text-decoration: none;
    color: #850101;
}

.appbar__active{
    color:#6E0101;
}
.home__modal-btn {
  cursor: pointer;
}
.home__modal-btn:hover {
  text-decoration: underline;
}

.home__mission-title-container {
  padding: 20px 10px 10px 10px;
}

.home-title{
  font-weight: 600;
}

.home__mission-title,
.home__mission-text {
  color: hsl(176, 100%, 84%);
}

.home__mission-text {
  font-size: 1.2rem;
  color: hsl(176, 100%, 84%);
  font-weight: 600;
  padding: 0 0 30px 0;
}


.home__welcome-text{
  color: hsl(0, 0%, 29%);
  padding: 20px;
  font-weight: 600;
}

.home__header-container {
  padding: 20px;
  /* background: hsl(220, 70%, 30%); */
  background: -webkit-linear-gradient(
    45deg,
    hsl(220, 70%, 30%), hsl(220, 85%, 37%)
    );
  background: linear-gradient(
    45deg,
    hsl(220, 70%, 30%), hsl(220, 85%, 37%)
    );
  border-top: 12px solid hsl(220, 70%, 20%);
}

.home__welcome-header-text {
  color: hsla(0, 0%, 100%, .90);
  text-shadow: 3px 1px 2px hsl(220, 70%, 20%);
  font-size: 3rem;

}


.home__welcome-text {
  margin: -10px 0 -10px 0;
  color: hsla(0, 0%, 100%, .90);
  line-height: 2.5em;
  font-size: 1.1rem;

}


/* JOIN US CONTAINER */
.home__join-us-container {
  padding: 20px;
  border-top: 6px solid hsl(357, 80%, 30%);
  background: hsl(357, 92%, 41%);
}

.home__join-us-header {
  color: hsl(0, 0%, 100%, 0.86);
  font-size: 1.5rem;
  font-weight: 700;
}

button.home__join-us-button  {
  background: hsl(357, 80%, 30%);
  border: none;
}

button.home__join-us-button:hover {
  background: hsl(357, 80%, 25%);
  border: none;
}

.home-img{
  position: absolute;
  max-width: 300px;
  right:100px;
  top: 500px;
  z-index: 10;
}
.l-fullscreen-content {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  height: calc(100% -60px);
}

.about__graphic {
  cursor: -webkit-zoom-in;
  cursor: zoom-in
}
.rr__header {
  border-bottom: 2px solid #ccc;
}

.rr__report-name {
  color: #391152;
  font-weight: 600;
}

.rr__report-link {
  color: #128AB7;
  margin-right: 10px;
}

.rr__report-section {
  border-bottom: 1px dotted #ccc;
}

.list-unstyled li {
  font-size: 1.3rem;

}

.list-unstyled li a {
  color: #eee;
}
.footer-container{
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #313642;
  color: white;
  border-top: 10px solid #656871;
  padding: 20px 0px;

}
body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



