.l-fullscreen-content {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  height: calc(100% -60px);
}
