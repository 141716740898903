.rr__header {
  border-bottom: 2px solid #ccc;
}

.rr__report-name {
  color: #391152;
  font-weight: 600;
}

.rr__report-link {
  color: #128AB7;
  margin-right: 10px;
}

.rr__report-section {
  border-bottom: 1px dotted #ccc;
}
