@import url("https://fonts.googleapis.com/css?family=Lato");
body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


