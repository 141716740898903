.appbar__container {
    border-top: 10px solid hsl(357, 80%, 30%);
    /* border-bottom: 2px solid #B60101; */
    box-shadow: 0px 0px 2px 0px #3e3e3e;
}

.nav-adjustments {
    padding-right: 20px;
    margin-bottom: 0px;
    list-style: none;
    bottom: 20px;
    position: absolute;
    right: 0;
}

.appbar__nav-link {
    color:#B60101;
    font-size: 1.2rem;
    font-weight: 600;
}

.appbar__nav-link:hover {
    text-decoration: none;
    color: #850101;
}

.appbar__active{
    color:#6E0101;
}