.list-unstyled li {
  font-size: 1.3rem;

}

.list-unstyled li a {
  color: #eee;
}
.footer-container{
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #313642;
  color: white;
  border-top: 10px solid #656871;
  padding: 20px 0px;

}